$border-basic: 1px solid #ccc;

#kingineGoMain::-webkit-file-upload-button,
#kingineGoSub::-webkit-file-upload-button {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

#previewStyle {
    float: left;
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 5px 5px 5px 0;
    padding: 0;
    border: $border-basic;
    background-image: url(/images/preview-image-default.gif);
    background-size: cover;
    background-position: center;
}

#kingineGoMain,
#kingineGoSub {
    @extend #previewStyle;
}

#kingineBox {
    overflow: hidden;
    padding-left: 5px;
    border: 2px dashed #fff;
}

#kingineWalletMain,
#kingineWalletSub {
    display: flex;
    float: left;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        @extend #previewStyle;
        background-image: url(/images/image-load.gif);

        img {
            width: 100%;
        }
    }
}
