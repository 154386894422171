/*

#productCategory,
#productCollection {
    border: none;
    box-shadow: none;
    font-size: 20px;
}

.formList01 {
    width: 350px;
    height: 70px;
    overflow-y: scroll;
    margin: 5px 0;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    list-style-type: none;

    li {
        float: left;
        margin: 0 5px;
    }

    a {
        text-decoration: none;
    }
}

@media only screen and (max-width : 480px) {
    .formList01 {
        width: 100%;
    }
}
*/
