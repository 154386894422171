html {
    //background-color: #4e5d6c;
}

body {
    position: relative;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
}

table {
    font-size: 14px !important;
}

td {
    padding: 10px 0 10px !important;
    border-bottom: 1px solid #4e5d6c !important;
}

.badge {
    font-size: 20px !important;
}

/*
.main-image #kingineWalletMain li {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 5px 5px 5px 0;
    border: 1px solid #ccc;
    background-size: cover;
    background-position: center;
}

.main-image #kingineWalletMain li a {
    display: none;
}

.main-image #kingineWalletMain li:hover a {
    display: block;
}

.other-image #kingineWalletSub02 {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.other-image #kingineWalletSub02 li {
    float: left;
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 5px 5px 5px 0;
    padding: 0;
    border: 1px solid #ccc;
    background-image: url(/images/preview-image-default.gif);
    background-size: cover;
    background-position: center;
}

.other-image #kingineWalletSub02 li a {
    display: none;
}

.other-image #kingineWalletSub02 li:hover a {
    display: block;
}
*/
#selectedProduct {

    .modal-content {
        border: 0;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        background-color: transparent;
    }

    button.close {
        color: #fff;
        text-shadow: 0 0 0 transparent;
    }

    #productImageUpdate {
        ul.gallery {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            list-style-type: none;

            li.gallery__item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                position: relative;
                width: 200px;
                height: 200px;
                overflow: hidden;
                margin: 0 20px 20px 0;
                border: 1px solid #dadada;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                .button-primary {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    color: #dedede;

                    .active {
                        color: #ffd700;
                    }
                }

                .button-remove {
                    color: #4e4e4e;;
                }

                .button-view {
                    color: #4e4e4e;;
                }

                button {
                    padding: 0;
                    opacity: 0.7;
                }
            }

            li.gallery__item:hover button {
                opacity: 1;
            }
        }
    }
}


#editMenu {
    .ui-sortable-placeholder {
        margin-bottom: 20px;
        visibility: visible !important;
        background-color: #d2d2d2;
    }

    .menu-sorting {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    #sender {
        min-width: 200px;
        min-height: 100px;
        margin: 0 20px 0 0;
        padding: 10px;
        background-color: #f7f7f7;
        list-style-type: none;
    }

    #receiver{
        min-width: 200px;
        min-height: 100px;
        margin: 0;
        padding: 10px;
        border: 1px dashed #bebebe;
        list-style-type: none;
    }

    a {
        display: block;
        margin-bottom: 5px;
        padding: 3px 10px;
        width: 100%;
        background-color: #737373;
        color: #ffffff;
    }
}

.breadcrumb {
    margin-bottom: 50px !important;
}
